import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Providers from "../../components/Providers";
import TestimonialSlider from "../../components/TestimonialSlider";
import ContactForm from "../../components/ContactForm";

const Provider = () => {
  return (
    <Layout>
      <PageHeader text="Meet Our Providers" />
      <Providers />
      <ContactForm />
      <TestimonialSlider />
    </Layout>
  );
};

export default Provider;
